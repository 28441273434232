import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuery, useSubscription, gql } from "@apollo/client";
import ReactJson from "react-json-view";
import { ShareparkDateTime } from "../../components/LocalTime";
import {
  GetSiteMessagesQuery,
  SiteMessageFilterInput,
} from "../../gql/graphql";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import ShareParkTable, {
  usePaginationAndFilters,
} from "../../components/table/ShareParkTable";

const GET = gql`
  query GetSiteMessages(
    $where: SiteMessageFilterInput
    $take: Int
    $skip: Int
  ) {
    siteMessages(
      where: $where
      take: $take
      skip: $skip
      order: { createdUtc: DESC }
    ) {
      items {
        id
        siteId
        type
        createdUtc
        payload
        response
        sequenceId
        duration
        transactionId
        site {
          name
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

const UPDATES = gql`
  subscription SiteMessageSent {
    siteMessageSent {
      id
      siteId
      type
      createdUtc
      payload
      response
      sequenceId
      duration
      transactionId
      site {
        name
      }
    }
  }
`;

type SiteMessage = NonNullable<
  NonNullable<GetSiteMessagesQuery["siteMessages"]>["items"]
>[number];

const columnHelper = createColumnHelper<SiteMessage>();

const columns = [
  columnHelper.accessor("site.name", {
    header: "Site",
    cell: (info) => {
      return (
        <>
          <Link to={"/sites/" + info.row.original?.siteId}>
            {info.row.original?.site?.name}
          </Link>
          <br />
          {info.row.original?.transactionId && (
            <Link to={"/transactions/" + info.row.original?.transactionId}>
              TX
            </Link>
          )}
        </>
      );
    },
  }),
  columnHelper.accessor("sequenceId", {
    header: "Sequence",
    enableColumnFilter: false,
  }),
  columnHelper.accessor("createdUtc", {
    header: "Timestamp",
    enableColumnFilter: false,
    cell: (info) => {
      return <ShareparkDateTime timestamp={info.row.original?.createdUtc} />;
    },
  }),
  columnHelper.accessor("type", {
    header: "Event Type",
  }),
  columnHelper.accessor("payload", {
    header: "Event Data",
    cell: (info) => {
      if (!info.row.original?.payload) return null;
      return (
        <ReactJson
          name={null}
          src={JSON.parse(info.row.original?.payload)}
          collapsed={1}
          style={{ fontSize: "0.75rem" }}
        />
      );
    },
  }),
  columnHelper.accessor("duration", {
    header: "Duration",
    // meta: {
    //   filterType: "range",
    // },
    enableColumnFilter: false,
  }),
  columnHelper.accessor("response", {
    header: "Result",
    enableColumnFilter: false,
    cell: (info) => {
      if (!info.row.original?.response) return null;
      let resp = {};
      try {
        resp = JSON.parse(info.row.original?.response);
      } catch (e) {
        // console.log(c?.response);
        // console.error(e);
      }
      return (
        <ReactJson
          name={null}
          src={resp}
          collapsed={1}
          style={{ fontSize: "0.75rem" }}
        />
      );
    },
  }),
];

export const SiteMessages = () => {
  const { pagination, setPagination, columnFilters, setColumnFilters } =
    usePaginationAndFilters();
  const where = React.useMemo(() => {
    const where: SiteMessageFilterInput = {};
    for (const filter of columnFilters) {
      switch (filter.id) {
        case "createdUtc":
          where[filter.id] = { gte: filter.value };
          break;
        case "sequenceId":
          if (filter.value != null) {
            where[filter.id] = { eq: parseInt(String(filter.value), 10) };
          }
          break;
        case "duration":
          if (filter.value != null) {
            const [min, max] = filter.value as [number?, number?];
            if (min == null && max == null) {
              break;
            }
            const f: SiteMessageFilterInput["duration"] = {};
            if (min != null) {
              f.gte = min;
            }
            if (max != null) {
              f.lte = max;
            }
            where[filter.id] = f;
          }
          break;
        case "site_name":
          if (filter.value != null) {
            where.site = { name: { contains: String(filter.value) } };
          }
          break;
        case "type":
        case "payload":
        case "response":
        case "transactionId":
          if (filter.value != null) {
            where[filter.id] = { contains: String(filter.value) };
          }
          break;
      }
    }
    return where;
  }, [columnFilters]);
  const [messages, setMessages] = useState<SiteMessage[]>([]);

  const latestMessages = useRef(messages);
  latestMessages.current = messages;

  const { data, loading } = useQuery<GetSiteMessagesQuery>(GET, {
    fetchPolicy: "no-cache",
    variables: {
      take: pagination.pageSize,
      skip: pagination.pageSize * pagination.pageIndex,
      where,
    },
  });

  const { data: subData } = useSubscription(UPDATES);

  // combine the data from the query and the subscription
  useEffect(() => {
    if (data) {
      setMessages(data.siteMessages?.items ?? []);
    }
  }, [data]);

  useEffect(() => {
    if (subData) {
      setMessages((prev) => [subData.siteMessageSent, ...prev]);
    }
  }, [subData]);

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Messages</h1>
      </div>

      <ShareParkTable
        data={
          data?.siteMessages
            ? { items: messages, totalCount: data.siteMessages.totalCount }
            : { items: [], totalCount: 0 }
        }
        columns={columns as ColumnDef<SiteMessage>[]}
        pagination={pagination}
        setPagination={setPagination}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        loading={loading}
      />
    </div>
  );
};
