import { useEffect, useState, useRef, FC } from "react";
import { gql, useMutation, useQuery, useSubscription } from "@apollo/client";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { GetBaysQuery, GetSiteQuery } from "../../gql/graphql";

const BAYS = gql`
  query GetBays($siteId: UUID!) {
    bays(siteId: $siteId, take: 1000) {
      items {
        id
        name
        areaId
        number
        isOccupied
        isOverstay
        senseIqId
        type
      }
    }
  }
`;

const UPDATE_BAY = gql`
  mutation UpdateBay($bay: BayInput) {
    updateBay(bay: $bay) {
      id
      name
      areaId
      number
      isOccupied
      isOverstay
      senseIqId
      type
    }
  }
`;

const TRIGGER_REFRESH = gql`
  mutation TriggerRefresh($siteId: UUID!) {
    triggerRefresh(siteId: $siteId) {
      id
    }
  }
`;

const SUBSCRIBE = gql`
  subscription BayUpdated($siteId: UUID!) {
    bayUpdated(siteId: $siteId) {
      id
      name
      areaId
      number
      isOccupied
      isOverstay
      senseIqId
      type
    }
  }
`;

type SiteType = NonNullable<
  NonNullable<GetSiteQuery["sites"]>["items"]
>[number];

const ev = (
  <path d="M19.77,7.23L19.78,7.22L16.06,3.5L15,4.56L17.11,6.67C16.17,7.03 15.5,7.93 15.5,9A2.5,2.5 0 0,0 18,11.5C18.36,11.5 18.69,11.42 19,11.29V18.5A1,1 0 0,1 18,19.5A1,1 0 0,1 17,18.5V14A2,2 0 0,0 15,12H14V5A2,2 0 0,0 12,3H6A2,2 0 0,0 4,5V21H14V13.5H15.5V18.5A2.5,2.5 0 0,0 18,21A2.5,2.5 0 0,0 20.5,18.5V9C20.5,8.31 20.22,7.68 19.77,7.23M18,10A1,1 0 0,1 17,9A1,1 0 0,1 18,8A1,1 0 0,1 19,9A1,1 0 0,1 18,10M8,18V13.5H6L10,6V11H12L8,18Z" />
);
const pram = (
  <path d="M17 20A2 2 0 0 1 15 22A2 2 0 0 1 13 20A2 2 0 0 1 15 18A2 2 0 0 1 17 20M7 20A2 2 0 0 1 5 22A2 2 0 0 1 3 20A2 2 0 0 1 5 18A2 2 0 0 1 7 20M17.61 3C16.95 3 16.44 3.2 16 3.5C15.32 3.91 14.88 4.59 14.47 5.07L5.71 15.35C5.16 16 5.62 17 6.47 17H14C15.11 17 16 16.1 16 15V6.38C16.58 5.7 16.93 5 17.61 5C18.38 5 19 5.66 19 6.5V7H21V6.5C21 4.56 19.5 3 17.61 3M8.86 3.09C7.04 3.16 5.23 3.76 3.68 4.9L8.44 9.66L12.32 5.1C12.59 4.78 12.91 4.38 13.3 4C12.14 3.45 10.9 3.15 9.65 3.09C9.39 3.08 9.12 3.08 8.86 3.09Z" />
);
const accessible = (
  <path d="M14 16L15.32 17.76C14.32 19.68 12.31 21 10 21C6.69 21 4 18.31 4 15C4 12.57 5.46 10.5 7.55 9.55L7.76 11.72C6.71 12.44 6 13.63 6 15C6 17.21 7.79 19 10 19C11.86 19 13.41 17.72 13.86 16H14M19.55 16.11L18.3 16.73L15.5 13H10.91L10.71 11H14V9H10.5L10.2 6C11.21 5.88 12 5.04 12 4C12 2.9 11.11 2 10 2S8 2.9 8 4C8 4.03 8 4.07 8 4.1H8L9.1 15H14.5L17.7 19.27L20.45 17.9L19.55 16.11Z" />
);

export const Status: FC<{ site: SiteType }> = ({ site }) => {
  const [bays, setBays] = useState<NonNullable<GetBaysQuery["bays"]>["items"]>(
    []
  );

  const [areaId, setAreaId] = useState<string | null>(null);

  const navigate = useNavigate();

  // need this for subscription?
  const latestBays = useRef<typeof bays>(bays);
  latestBays.current = bays;

  const { data: baysData } = useQuery<GetBaysQuery>(BAYS, {
    variables: { siteId: site?.id },
    fetchPolicy: "no-cache",
  });
  const { data: subData } = useSubscription(SUBSCRIBE, {
    variables: { siteId: site?.id },
  });
  const [triggerRefresh] = useMutation(TRIGGER_REFRESH, {
    variables: { siteId: site?.id },
  });
  const [updateBay] = useMutation(UPDATE_BAY);

  useEffect(() => {
    if (baysData?.bays?.items) {
      setBays(baysData.bays.items);
    }
  }, [baysData]);

  useEffect(() => {
    if (subData && latestBays.current) {
      var latest = [...latestBays.current];
      latest[latest.findIndex((x) => x?.id === subData.bayUpdated.id)] =
        subData.bayUpdated;
      setBays(latest);
    }
  }, [subData]);

  const updateSite = () => {
    triggerRefresh({ variables: { siteId: site?.id } }).then((x) => {
      if (x.data.triggerRefresh) {
        toast(`Get Site Configuration command sent`, {
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        });
        navigate("/sites");
      }
    });
  };

  const editArea = (areaId: string) => {
    setAreaId(areaId);
  };

  const toggleType = (bayId: string, type: string) => {
    console.log(bayId, type);

    const bay = bays?.find((b) => b?.id === bayId);
    if (bay) {
      const updatedBay = {
        ...bay,
        type: bay.type === type ? null : type,
        pgsEnabled: true,
        isActive: true,
      };
      const updatedBays = bays?.map((b) => {
        if (b?.id === bayId) {
          return updatedBay;
        }
        return b;
      });
      setBays(updatedBays);

      updateBay({ variables: { bay: updatedBay } }).then((x) => {
        if (x.data.updateBay) {
          toast(`${bay.name} updated`, {
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
          });
        }
      });
    }
  };

  const showBayType = (type: string) => {
    switch (type) {
      case "pram":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            fill="#ffffff"
          >
            {pram}
          </svg>
        );
      case "ev":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            fill="#ffffff"
          >
            {ev}
          </svg>
        );
      case "accessible":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            fill="#ffffff"
          >
            {accessible}
          </svg>
        );
      default:
        return "";
    }
  };

  return (
    <>
      <div className="text-end">
        <button className="btn btn-primary" onClick={() => updateSite()}>
          Get Site Configuration
        </button>
      </div>

      {site?.areas?.map((area) => {
        return (
          <div key={area?.id} className="card mt-3">
            <div className="card-header">
              {areaId === area?.id ? (
                <button
                  className="btn btn-sm btn-secondary float-end"
                  onClick={() => editArea("")}
                >
                  Done
                </button>
              ) : (
                <button
                  className="btn btn-sm btn-secondary float-end"
                  onClick={() => editArea(area?.id)}
                >
                  Edit
                </button>
              )}
              <strong>{area?.name}</strong> - Capacity: {area?.capacity},
              Occupied:{" "}
              {
                bays?.filter((b) => b?.areaId === area?.id && b?.isOccupied)
                  .length
              }
              , Free:{" "}
              {
                bays?.filter((b) => b?.areaId === area?.id && !b?.isOccupied)
                  .length
              }
            </div>

            {areaId !== area?.id && (
              <div className="card-body">
                {bays
                  ?.filter((b) => b?.areaId === area?.id)
                  .map((bay, index) => {
                    return (
                      <span
                        key={bay?.id}
                        className={
                          "badge me-1 mb-3 " +
                          (bay?.isOccupied ? "bg-danger" : "bg-success")
                        }
                      >
                        {bay?.name} {bay?.type ? showBayType(bay?.type) : ""}
                      </span>
                    );
                  })}
              </div>
            )}

            {areaId === area?.id && (
              <div className="card-body">
                <div className="d-flex">
                  {bays
                    ?.filter((b) => b?.areaId === area?.id)
                    .map((bay, index) => {
                      return (
                        <div
                          key={bay?.id}
                          className={
                            "me-1 mb-3 border p-1 inline-block" +
                            (bay?.isOccupied
                              ? " bg-danger-subtle border-danger"
                              : " bg-success-subtle border-success")
                          }
                        >
                          <div className="text-center">{bay?.name}</div>
                          <svg
                            className={
                              "cursor-pointer" +
                              (bay?.type === "pram"
                                ? "opacity-100"
                                : " opacity-25")
                            }
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width={24}
                            onClick={() => toggleType(bay?.id, "pram")}
                          >
                            {pram}
                          </svg>
                          <svg
                            className={
                              "cursor-pointer" +
                              (bay?.type === "ev"
                                ? "opacity-100"
                                : " opacity-25")
                            }
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width={24}
                            onClick={() => toggleType(bay?.id, "ev")}
                          >
                            {ev}
                          </svg>
                          <svg
                            className={
                              "cursor-pointer" +
                              (bay?.type === "accessible"
                                ? "opacity-100"
                                : " opacity-25")
                            }
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width={24}
                            onClick={() => toggleType(bay?.id, "accessible")}
                          >
                            {accessible}
                          </svg>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};
