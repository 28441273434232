import { gql, useMutation, useQuery } from "@apollo/client";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Area, PricePlan } from "../../gql/graphql";
import { useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { TextInput } from "../../components/TextInput";
import { toast } from "react-toastify";

const GET = gql`
  query GetPricePlan($siteId: UUID!, $pricePlanId: UUID!) {
    pricePlans(where: { siteId: { eq: $siteId }, id: { eq: $pricePlanId } }) {
      items {
        id
        name
        startDate
        endDate
        isActive
      }
    }
  }
`;

const GET_SITE = gql`
  query GetSite($id: UUID!) {
    sites(where: { id: { eq: $id } }) {
      items {
        id
        name
        areas {
          id
          name
          capacity
        }
      }
    }
  }
`;

const UPDATE = gql`
  mutation UpdatePricePlan($pricePlan: PricePlanInput!) {
    updatePricePlan(pricePlan: $pricePlan) {
      id
      name
      startDate
      endDate
      isActive
    }
  }
`;

export const PricePlanEdit = () => {
  let { siteId, pricePlanId, tab } = useParams();
  const navigate = useNavigate();

  const [pricePlan, setPricePlan] = useState<Partial<PricePlan> | null>(null);

  const { data } = useQuery(GET, {
    variables: {
      pricePlanId:
        pricePlanId === "new" || !pricePlanId
          ? "00000000-0000-0000-0000-000000000000"
          : pricePlanId,
      siteId: siteId,
    },
  });

  const { data: site } = useQuery(GET_SITE, {
    variables: {
      id: siteId,
    },
  });

  console.log(tab);

  const [updatePricePlan, { loading: updating }] = useMutation<
    {
      updatePricePlan: PricePlan;
    },
    {
      pricePlan: PricePlan;
    }
  >(UPDATE, {
    refetchQueries:
      pricePlanId === "new"
        ? [
            {
              query: GET,
              variables: {
                pricePlanId:
                  pricePlanId === "new" || !pricePlanId
                    ? "00000000-0000-0000-0000-000000000000"
                    : pricePlanId,
                siteId: siteId,
              },
            },
          ]
        : [],
    awaitRefetchQueries: true,
  }); // [ updateCompany, { data, loading, error }]

  useEffect(() => {
    if (pricePlanId === "new") {
      setPricePlan({
        id: "00000000-0000-0000-0000-000000000000",
        name: "",
        startDate: null as Date | null,
        endDate: null as Date | null,
        isActive: false,
      });
    }
    const [pricePlan] = data?.pricePlans?.items ?? [];
    if (pricePlan) {
      setPricePlan(pricePlan);
    }
  }, [data, pricePlanId]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    let update = {
      ...(data as PricePlan),
      startDate: new Date(data.startDate),
      endDate: data.endDate ? new Date(data.endDate) : null,
      siteId: siteId,
      id: pricePlan?.id!,
      isActive: pricePlan?.isActive!,
    };

    updatePricePlan({
      variables: {
        pricePlan: update,
      },
    }).then((x) => {
      if (x.data?.updatePricePlan) {
        toast(`${x.data?.updatePricePlan.name} saved`, {
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        });
        navigate("/sites/" + siteId + "/tariffs");
      }
    });
  };

  return (
    <>
      {pricePlan && (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
            <h1 className="h2">Price Plan</h1>
          </div>

          <ul className="nav nav-tabs mb-3">
            <li className="nav-item">
              <Link
                className={`nav-link ${!tab ? "active" : ""}`}
                to={`/sites/${siteId}/price-plans/${pricePlanId}`}
              >
                Details
              </Link>
            </li>

            {site &&
              pricePlanId !== "new" &&
              site?.sites.items[0].areas?.length > 0 &&
              site?.sites.items[0].areas?.map((area: Area) => (
                <li className="nav-item" key={area.id}>
                  <Link
                    className={`nav-link ${tab === area.id ? "active" : ""}`}
                    to={`/sites/${siteId}/price-plans/${pricePlanId}/${area.id}`}
                  >
                    {area.name}
                  </Link>
                </li>
              ))}
          </ul>

          {!tab && (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={
                "needs-validation" +
                (Object.keys(errors).length > 0 ? " was-validated" : "")
              }
              noValidate
            >
              <div className="row">
                <div className="col-md">
                  <TextInput
                    name="name"
                    label="Name"
                    defaultValue={pricePlan?.name ?? ""}
                    placeholder="Price Plan Name"
                    register={register}
                    config={{ required: true }}
                    required={true}
                    errors={errors}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md">
                  <TextInput
                    name="startDate"
                    label="Start Date"
                    type="date"
                    defaultValue={
                      pricePlan?.startDate && pricePlan?.startDate !== null
                        ? pricePlan.startDate.split("T")[0]
                        : ""
                    }
                    placeholder="Description"
                    register={register}
                    config={{ required: true }}
                    required={true}
                    errors={errors}
                  />
                  <span className="text-info">
                    Plan will start at 00:00 (site time) on this day
                  </span>
                </div>
                <div className="col-md">
                  <TextInput
                    name="endDate"
                    label="End Date"
                    type="date"
                    defaultValue={
                      pricePlan?.endDate ? pricePlan.endDate.split("T")[0] : ""
                    }
                    placeholder="Description"
                    register={register}
                    config={{ required: false }}
                    required={false}
                    errors={errors}
                  />
                  <span className="text-info">
                    Plan will end at midnight (site time) on this day
                  </span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md">
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="isActive"
                    autoComplete="off"
                    onClick={() =>
                      setPricePlan({
                        ...pricePlan,
                        isActive: !pricePlan.isActive,
                      })
                    }
                  />
                  <label
                    className={
                      pricePlan?.isActive ? "btn btn-success" : "btn btn-danger"
                    }
                    htmlFor="isActive"
                  >
                    {pricePlan?.isActive ? "Active" : "Inactive"}
                  </label>
                </div>
              </div>

              <div className="mt-3 mb-3">
                <input
                  // disabled={updating}
                  className="btn btn-primary"
                  type="submit"
                  value="Save"
                />{" "}
                <Link to={`/sites/${siteId}/tariffs`} className="btn btn-link">
                  Cancel
                </Link>
              </div>
            </form>
          )}
        </>
      )}
    </>
  );
};
