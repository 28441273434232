import { gql, useQuery } from "@apollo/client";
import { GetPricePlansQuery, GetSiteQuery } from "../../gql/graphql";
import { FC } from "react";
import { Link } from "react-router-dom";

export const PRICE_PLANS = gql`
  query GetPricePlans($siteId: UUID!, $take: Int, $skip: Int) {
    pricePlans(where: { siteId: { eq: $siteId } }, take: $take, skip: $skip) {
      totalCount
      items {
        id
        name
        startDate
        endDate
        isActive
      }
    }
  }
`;

type SiteType = NonNullable<
  NonNullable<GetSiteQuery["sites"]>["items"]
>[number];

const PricePlans: FC<{ site: SiteType }> = ({ site }) => {
  const { data, loading } = useQuery<GetPricePlansQuery>(PRICE_PLANS, {
    variables: {
      siteId: site?.id,
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-2">
        <h1 className="h5">Price Plans</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Link
            className="btn btn-sm btn-primary ml-2"
            to={`/sites/${site?.id}/price-plans/new`}
          >
            Add New
          </Link>
        </div>
      </div>
      <div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
          </thead>
          <tbody>
            {data?.pricePlans?.items &&
              data?.pricePlans?.items.map(
                (p) =>
                  p && (
                    <tr
                      key={p.id}
                      className={p.isActive ? "table-success" : ""}
                    >
                      <td>
                        <Link to={`/sites/${site?.id}/price-plans/${p.id}`}>
                          {p.name}
                        </Link>
                      </td>
                      <td>{p.startDate.split("T")[0]}</td>
                      <td>{p.endDate?.split("T")[0]}</td>
                    </tr>
                  )
              )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PricePlans;
